<template>
  <v-app>
    <v-col cols="11" class="mx-auto">
      <v-row justify="start" align="center">
        <v-col cols="12">
          <p class="display-1">
            Payment Schedule No {{ scheduleId }} of {{ schedules.length }}
          </p>
        </v-col>
        <v-col cols="12">
          <strong class="mr-6">Payment Schedule ID: {{ scheduleId }}</strong>
          <strong class="mr-6">Batch ID: {{ batch.batch_id }}</strong>
        </v-col>
      </v-row>

      <v-row>
        <!-- batch reason textarea -->
        <v-col cols="6">
          <p class="font-weight-bold text-uppercase">Total amount confirmed</p>
          <p class="p-3 d-flex grey lighten-2 rounded mb-3">
            <small class="mr-auto">{{ batch.total_approved }}</small>
            <small>(NGN)</small>
          </p>
          <p class="font-weight-bold text-uppercase">
            Reason for acceptance or rejection
          </p>
          <v-textarea
            v-model="batch.reason_approved_or_denied"
            outlined
            placeholder="Type here"
          ></v-textarea>
          <!-- show loading indicator -->
          <v-row justify="center" v-if="isLoading">
            <v-col class="text-center">
              <v-progress-circular
                :size="30"
                :width="3"
                color="green darken-4"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <!-- hide loading indicator -->
          <v-row justify="start" v-else>
            <v-col cols="4" class="mr-auto">
              <v-btn
                medium
                color="green darken-4"
                class="white--text text-capitalize"
                @click.prevent="handleComplete"
                >Schedule Payment</v-btn
              >
            </v-col>
            <v-col cols="4">
              <v-btn
                medium
                color="red darken-4"
                class="white--text text-capitalize"
                @click.prevent="handleCancel"
                >Cancel Payment</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <!-- claim record -->
        <v-col cols="6" class="ml-auto">
          <p class="font-weight-bold text-uppercase mb-2 pl-12">
            Total number of claims recorded
            <small class="ml-4 grey lighten-2 ml-5 px-3">{{
              batch.total_claims
            }}</small>
          </p>
          <p class="font-weight-bold text-uppercase mb-2 pl-12">
            Total number of claims vetted
            <small class="ml-4 grey lighten-2 ml-12 px-3">{{
              batch.vetted_claims
            }}</small>
          </p>
          <!-- claims link -->
          <router-link
            :to="{name: 'list-approve-claims', params: {batch_id: batch.id}}"
            class="pl-12"
          >
            <small class="font-weight-bold"
              >See claim records in this batch</small
            >
          </router-link>
        </v-col>
      </v-row>
      <!-- schedule warning modal -->
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title class="headline">
            <strong>Attention!</strong>
          </v-card-title>

          <v-card-text>{{ message }}</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              class="text-capitalize"
              text
              @click="dialog = false"
            >
              <strong>Close</strong>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end schedule warning modal -->
    </v-col>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "schedule-batch",
  data() {
    return {
      dialog: false,
      message: "",
    };
  },
  computed: {
    ...mapGetters({
      schedules: "claimModule/schedules",
      batches: "claimModule/batches",
      isLoading: "claimModule/isLoading",
    }),
    scheduleId() {
      return Number(this.$route.params.batch_id);
    },
    batch() {
      const scheduleBatch = this.schedules.find(
        (schedule) => schedule.id === this.scheduleId
      );
      return scheduleBatch;
    },
  },
  methods: {
    ...mapActions({
      complete: "claimModule/completeSchedule",
    }),
    async handleComplete() {
      // approve batch before updating
      if (
        this.batch.reason_approved_or_denied === "" ||
        this.batch.reason_approved_or_denied === null
      ) {
        this.dialog = true;
        this.message =
          "Please provide a reason for accepting payment for this batch.";
        return;
      }
      this.batch.is_approved = true;
      const response = await this.complete({ data: this.batch });
      if (response["status"] === 200) {
        this.$router.push({ name: "list-schedule-batches" });
      }
    },
    async handleCancel() {
      // approve batch before updating
      if (
        this.batch.reason_approved_or_denied === "" ||
        this.batch.reason_approved_or_denied === null
      ) {
        this.dialog = true;
        this.message =
          "Please provide a reason for rejecting payment for this batch.";
        return;
      }
      // cancle batch before updating
      this.batch.is_approved = false;
      // this.batch.reason_approved_or_denied = "";
      const response = await this.complete({ data: this.batch });
      if (response["status"] === 200) {
        this.$router.push({ name: "list-schedule-batches" });
      }
    },
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
</style>
