import { render, staticRenderFns } from "./ScheduleBatch.vue?vue&type=template&id=18b2654c&scoped=true&"
import script from "./ScheduleBatch.vue?vue&type=script&lang=js&"
export * from "./ScheduleBatch.vue?vue&type=script&lang=js&"
import style0 from "./ScheduleBatch.vue?vue&type=style&index=0&id=18b2654c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b2654c",
  null
  
)

export default component.exports